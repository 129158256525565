import React from 'react';
import axios from 'axios';
import App from 'next/app';
import Head from 'next/head';
import parse from 'html-react-parser';
import {
    NSToast, initAqlrc, initPage, jwtManager, getUser, logoutUser
} from 'aqlrc';
import getAPIUrl from '../lib/getAPIUrl';
import { Router } from '../routes';
import '../styles/style.css';
import 'react-responsive-modal/styles.css';
import 'moment/locale/fr';

export default class AquilaApp extends App {
    static async getInitialProps(bundle) {
        initAqlrc();

        let pageProps = {};
        let cache = null;
        let appurl = null;
        let sitename = null;
        let demo = null;
        let langs = null;
        if (typeof window !== 'undefined') {
            cache = window.localStorage.getItem('cache');
            if (cache) {
                cache = JSON.parse(cache);
            }
        }
        if (!cache || (cache && Date.now() >= cache.date)) {
            const resConf = await axios.post(`${getAPIUrl(bundle.ctx)}v2/config`, {
                PostBody: {structure: {environment: 1}}
            });
            appurl = resConf.data.environment.appUrl;
            sitename = resConf.data.environment.siteName;
            demo = resConf.data.environment.demoMode;
            const resLangs = await axios.post(`${getAPIUrl(bundle.ctx)}v2/languages`, { PostBody: { limit: 99 } }); // Récupération des langues
            langs = resLangs.data.datas;
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cache', JSON.stringify({
                    appurl, sitename, demo, langs, date : Date.now() + 172800000
                }));
            }
        } else {
            appurl = cache.appurl;
            sitename = cache.sitename;
            demo = cache.demo;
            langs = cache.langs;
        }

        const lang = bundle.ctx && bundle.ctx.query && bundle.ctx.query.lang ? bundle.ctx.query.lang : langs.find((l) => l.defaultLanguage === true).code;
        if (typeof window !== 'undefined') {
            if (!window.localStorage.getItem('lang')) { window.localStorage.setItem('lang', lang); }
        }

        const routerLang = lang === langs.find((l) => l.defaultLanguage === true).code ? null : lang; // NE PAS TOUCHER !
        const urlLang = lang === langs.find((l) => l.defaultLanguage === true).code ? '' : `/${lang}`; // NE PAS TOUCHER !

        if (bundle.Component.getInitialProps && bundle) {
            bundle.ctx.nsGlobals = {
                Router, langs, lang, routerLang, urlLang
            }; // Permet d'envoyer les globales dans les getInitialProps de chaque page
            pageProps = await bundle.Component.getInitialProps(bundle.ctx);
        }

        // Récupération des données des blocs CMS header / footer + breadcrumb
        const initData = await initPage(bundle.ctx, lang);

        // La props "userRequired" est un objet défini dans chaque page
        // Il est composé de 2 propriétés :
        // "url" : l'URL de redirection (si on est côté client)
        // "route" : la route de redirection (si on est côté serveur)
        // Elle permet de restreindre les pages qui nécessitent un utilisateur connecté
        // En fonction de ça on redirige ou non vers la page d'acceuil
        let user = jwtManager.getUser(bundle.ctx);
        if (pageProps.userRequired) {
            if (user) {
                try {
                    const PostBody = {
                        structure : {
                            isActiveAccount   : 1,
                            company           : 1,
                            civility          : 1,
                            preferredLanguage : 1,
                            type              : 1,
                            addresses         : 1,
                            delivery_address  : 1,
                            billing_address   : 1,
                            phone_mobile      : 1
                        }
                    };
                    user = await getUser(user._id, PostBody, bundle.ctx);
                } catch (err) {
                    if (err.response && err.response.data && err.response.data.message) {
                        NSToast.error(err.response.data.message);
                    } else {
                        NSToast.error('common:error_occured');
                        console.error(err);
                    }
                    user = undefined;
                }
            }
            if (!user || !Object.keys(user).length) {
                user = undefined;

                // Déconnexion
                await logoutUser(bundle.ctx);

                if (bundle.ctx.req) {
                    return bundle.ctx.res.redirect(`${urlLang}${pageProps.userRequired.url ? pageProps.userRequired.url : ''}`);
                }
                return Router.pushRoute(pageProps.userRequired.route ? pageProps.userRequired.route : 'home', { lang: routerLang });
            }
        }
        let companyName = '';
        if (user) {
            try {
                const resCompany = await axios.get(`${getAPIUrl(bundle.ctx)}addon-cbo-fournisseur/user/company`);
                companyName = resCompany.data.datas;
            }
            catch(e) {
                console.error(e);
            }
        }

        // Bloc CMS du header et de la barre de cookie
        const cmsHead = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/head`, { lang });
        const cmsCookieBanner = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/cookie-banner`, { lang });

        pageProps = {
            ...pageProps,
            ...initData,
            demo,
            cmsHead       : cmsHead.data.content,
            companyName,
            messageCookie : cmsCookieBanner.data.content,
            appurl,
            sitename,
            currentUrl    : bundle.ctx.asPath, // => NSMenu
            langs,
            lang,
            routerLang,
            urlLang,
            user
        };
        return { pageProps };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    /* static async getDerivedStateFromProps() {
        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }
    } */

    componentDidMount = () => {
        /* Évènements levés pour Google Analytics */
        const init = new CustomEvent('init', {});
        window.dispatchEvent(init);
        let logPageView = new CustomEvent('logPageView', { detail: { url: window.location.pathname } });
        window.dispatchEvent(logPageView);

        Router.onRouteChangeStart = () => {
            const routeChange = new CustomEvent('routeChange', {});
            window.dispatchEvent(routeChange);
        };

        Router.router.events.on('routeChangeComplete', (url) => {
            logPageView = new CustomEvent('logPageView', { detail: { url } });
            window.dispatchEvent(logPageView);
        });
    }

    render() {
        const { Component, pageProps } = this.props;

        return (
            <>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
                    <meta property="og:site_name" content={pageProps.sitename} />
                    <meta property="og:type" content="website" />
                    <meta itemProp="name" content={pageProps.sitename} />
                    {
                        !pageProps.demo ? <meta name="robots" content="index,follow" />
                            : (
                                <>
                                    <meta name="robots" content="noindex,nofollow,noarchive" />
                                    <style>{`
                                        body::before { 
                                            content: "/!\\\\ This is a demo mode ! /!\\\\";
                                            background-color: red;
                                            color: #000;
                                            padding: 2px;
                                            width: 100%;
                                            text-align: center;
                                            position: fixed;
                                            z-index: 999;
                                            font-size: 11px;
                                        }
                                    `}</style>
                                </>
                            )
                    }
                    { pageProps.cmsHead ? parse(pageProps.cmsHead) : null }
                </Head>
                <Component {...pageProps} />
            </>
        );
    }
}
