const routes = (module.exports = require('next-routes')());

/**
 *  Multilangue pattern: /:lang([a-z]{2})?
 */

routes
    .add({ name: 'home', pattern: '/:lang([a-z]{2})?', page: 'static' })
    .add({ name: 'resetPass', pattern: '/:lang([a-z]{2})?/resetpass', page: 'resetPassword' })
    .add({ name: 'accountHome', pattern: '/:lang([a-z]{2})?/account', page: 'account/home' })
    .add({ name: 'accountProvider', pattern: '/:lang([a-z]{2})?/account/provider', page: 'account/provider' })
    .add({ name: 'invoiceSearch', pattern: '/:lang([a-z]{2})?/account/invoice', page: 'account/invoice' })
    .add({ name: 'invoiceAmountSearch', pattern: '/:lang([a-z]{2})?/account/amount', page: 'account/amount' })
    .add({ name: 'invoiceDispute', pattern: '/:lang([a-z]{2})?/account/dispute', page: 'account/dispute' })
    .add({ name: 'account', pattern: '/:lang([a-z]{2})?/account/account', page: 'account/account' })
    .add({ name: 'staticI18n', pattern: '/:lang([a-z]{2})?/:_slug', page: 'static' });
